import React from 'react';
import AboutPic from '../../../../asset/Road side view.jpg';
import { NavLink } from 'react-router-dom';

const About = () => {
    return (
        <div>
            <div className='about_parent'>

                <div className='second_about'>
                    <div className='text_second_about'>
                        <h4>About Us</h4>
                        <p>ARYANS was set up in 1999 as a tribute to friendship by a team of qualified professionals and engineers. It has a boutique real estate experience and has delivered more than 500 high quality aspirational homes at an exceptional value by fulfilling the dreams of their esteemed customers owning homes in reality. The ethics of the company is to mitigate risk through granular level of due diligence to ensure delivering hassle free aesthetic property with innovation being core. We believe in transferring every relationship into a positive, engaging, memorable experience that our customers would love to come back to recommend our products being the Brand Ambassador of our company which creates a sense of trust & credibility for us more than any expensive advertisements.</p>
                        <button><NavLink to='/about' onClick={() => window.scrollTo(0, 0)}>Know more about us</NavLink></button>
                    </div>
                    <img className='img_second_about' data-aos="fade-right" src={AboutPic} />
                </div>

            </div>
        </div>
    )
}

export default About