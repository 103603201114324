import React, { useEffect, useState } from 'react'

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

import './Testimonial.css';

const Testimonial = () => {

    const [slidesPerView, setSlidesPerView] = useState(2);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 767) {
                setSlidesPerView(1);
            } else {
                setSlidesPerView(2);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Set initial value

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (

        <Swiper
            slidesPerView={slidesPerView}
            spaceBetween={30}
            autoplay={{
                delay: 1000,
                disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper">

            <SwiperSlide className='sider_div'>
                <p className='sider_div_p'><q className='sider_q'>I am absolutely delighted with my new house and flat! The attention to detail in the design, the prime location, and the exceptional amenities make it a truly extraordinary place to call home. I couldn't be happier! ⭐️⭐️⭐️⭐</q></p>
                <div className='testi_data'>
                    <img src='https://www.w3schools.com/howto/img_avatar.png' />
                    <div className='testi_info'>
                        <h5>Dhanajaya rout</h5>
                    </div>
                </div>
            </SwiperSlide>

            <SwiperSlide className='sider_div'>
                <p className='sider_div_p'><q className='sider_q'>I am beyond thrilled with my new house and flat! The cozy and inviting atmosphere, combined with the convenient location and thoughtful layout, make it the perfect sanctuary for me. It's truly a place where I can create lifelong memories</q></p>
                <div className='testi_data'>
                    <img src='https://www.w3schools.com/howto/img_avatar.png' />
                    <div className='testi_info'>
                        <h5>Vijayashree Pradhan</h5>
                    </div>
                </div>
            </SwiperSlide>

            <SwiperSlide className='sider_div'>
                <p className='sider_div_p'><q className='sider_q'>I am absolutely delighted with my new house and flat! The attention to detail in the design, the prime location, and the exceptional amenities make it a truly extraordinary place to call home. I couldn't be happier! ⭐️⭐️⭐️⭐</q></p>
                <div className='testi_data'>
                    <img src='https://www.w3schools.com/howto/img_avatar.png' />
                    <div className='testi_info'>
                        <h5>Mukteshwar Panda</h5>
                    </div>
                </div>
            </SwiperSlide>


            <SwiperSlide className='sider_div'>
                <p className='sider_div_p'><q className='sider_q'>I am beyond thrilled with my new house and flat! The cozy and inviting atmosphere, combined with the convenient location and thoughtful layout, make it the perfect sanctuary for me. It's truly a place where I can create lifelong memories</q></p>
                <div className='testi_data'>
                    <img src='https://www.w3schools.com/howto/img_avatar.png' />
                    <div className='testi_info'>
                        <h5>Kartik Chandra Das</h5>
                    </div>
                </div>
            </SwiperSlide>


            <SwiperSlide className='sider_div'>
                <p className='sider_div_p'><q className='sider_q'>I am absolutely delighted with my new house and flat! The attention to detail in the design, the prime location, and the exceptional amenities make it a truly extraordinary place to call home. I couldn't be happier! ⭐️⭐️⭐️⭐</q></p>
                <div className='testi_data'>
                    <img src='https://www.w3schools.com/howto/img_avatar.png' />
                    <div className='testi_info'>
                        <h5>Sarojkant Behera</h5> 
                    </div>
                </div>
            </SwiperSlide>

        </Swiper>
    )
}

export default Testimonial