import React, { useEffect, useState } from 'react';
import './About.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import AboutPic from '../../asset/Road side view.jpg';
import Testimonial from './Testimonial/Testimonial';
import Carousel from '../Carousel/carousel';

const About = () => {

  useEffect(() => {
    AOS.init();
  }, [])


  return (
    <div>

      <div className='top_about'>
        <Carousel />
      </div>

      <div className='about_parent'>

        <div className='second_about'>
          <div className='text_second_about'>
            <h4>About Us</h4>
            <p>ARYANS was set up in 1999 as a tribute to friendship by a team of qualified professionals and engineers. It has a boutique real estate experience and has delivered more than 500 high quality aspirational homes at an exceptional value by fulfilling the dreams of their esteemed customers owning homes in reality. The ethics of the company is to mitigate risk through granular level of due diligence to ensure delivering hassle free aesthetic property with innovation being core. We believe in transferring every relationship into a positive, engaging, memorable experience that our customers would love to come back to recommend our products being the Brand Ambassador of our company which creates a sense of trust & credibility for us more than any expensive advertisements.</p>
          </div>
          <img className='img_second_about' data-aos="fade-right" src={AboutPic} />
        </div>


        {/* <div className='third_about'>
          <h1>Our Team</h1>
          <hr className='about_hr'></hr>
          <div className='person_div' data-aos="fade-left">
            <img src='https://www.w3schools.com/howto/img_avatar.png' />
            <div>
              <h4>Andrue Tate</h4>
              <p>Siobhan said that the book should begin with something to grab people’s attention. That is why I started with the dog. I also started with the dog because it happened to me and I find it hard to imagine things which did not happen to me.
                I said that I wanted to write about something</p>
            </div>

          </div>

          <div className='person_div' data-aos="fade-right">
            <div>
              <h4>Tony Pro</h4>
              <p>Siobhan said that the book should begin with something to grab people’s attention. That is why I started with the dog. I also started with the dog because it happened to me and I find it hard to imagine things which did not happen to me.
                I said that I wanted to write about something</p>
            </div>
            <img src='https://www.w3schools.com/howto/img_avatar2.png' />

          </div>

          <div className='person_div' data-aos="fade-left">
            <img src='https://www.w3schools.com/howto/img_avatar.png' />
            <div>
              <h4>Angela Yu</h4>
              <p>Siobhan said that the book should begin with something to grab people’s attention. That is why I started with the dog. I also started with the dog because it happened to me and I find it hard to imagine things which did not happen to me.
                I said that I wanted to write about something</p>
            </div>

          </div>

          <div className='person_div' data-aos="fade-right">
            <div>
              <h4>Tony Dan Ku</h4>
              <p>Siobhan said that the book should begin with something to grab people’s attention. That is why I started with the dog. I also started with the dog because it happened to me and I find it hard to imagine things which did not happen to me.
                I said that I wanted to write about something</p>
            </div>
            <img src='https://www.w3schools.com/howto/img_avatar2.png' />

          </div>

        </div> */}


        <div className='testimonial_div'>
          <h3>Client Review</h3>
          <hr className='hr_test'></hr>
          <Testimonial />
        </div>

      </div>


    </div>

  )
}

export default About;