import React, { useEffect, useState } from 'react';

import imgOne from '../../asset/home/Aerial view (day).jpg';
import imgTwo from '../../asset/home/Aerial view (night).jpg';
import imgThree from '../../asset/home/Gate view.jpg';
import imgFour from '../../asset/home/Landscape view.jpg';
import imgFive from '../../asset/home/Balcony view.jpg';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

import './Aryavarta.css';

const Aryavarta = () => {
    const [slidesPerView, setSlidesPerView] = useState(2);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 767) {
                setSlidesPerView(1);
            } else {
                setSlidesPerView(2);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Set initial value

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>

            <div>
                <Swiper
                    spaceBetween={30}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper">

                    <SwiperSlide className='carousel_sliderr'>
                        <img src={imgOne} />

                        <div className="overlay">
                            <h1>Affordable Housing at ₹ 48 Lacs Onwards</h1>
                            <p>Aryavarta, a 1.14 acre residential property located just 16 kms away from Bhubaneswar offers easy access to the city's amenities while providing a peaceful retreat from the hustle and bustle.</p>
                        </div>

                    </SwiperSlide>

                    <SwiperSlide className='carousel_sliderr'>
                        <img src={imgTwo} />
                        <div className="overlay">
                            <h1>Affordable Housing at ₹ 48 Lacs Onwards</h1>
                            <p>Aryavarta, a 1.14 acre residential property located just 16 kms away from Bhubaneswar offers easy access to the city's amenities while providing a peaceful retreat from the hustle and bustle.</p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className='carousel_sliderr'>
                        <img src={imgThree} />
                        <div className="overlay">
                            <h1>Affordable Housing at ₹ 48 Lacs Onwards</h1>
                            <p>Aryavarta, a 1.14 acre residential property located just 16 kms away from Bhubaneswar offers easy access to the city's amenities while providing a peaceful retreat from the hustle and bustle.</p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className='carousel_sliderr'>
                        <img src={imgFour} />
                        <div className="overlay">
                            <h1>Affordable Housing at ₹ 48 Lacs Onwards</h1>
                            <p>Aryavarta, a 1.14 acre residential property located just 16 kms away from Bhubaneswar offers easy access to the city's amenities while providing a peaceful retreat from the hustle and bustle.</p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className='carousel_sliderr'>
                        <img src={imgFive} />
                        <div className="overlay">
                            <h1>Affordable Housing at ₹ 48 Lacs Onwards</h1>
                            <p>Aryavarta, a 1.14 acre residential property located just 16 kms away from Bhubaneswar offers easy access to the city's amenities while providing a peaceful retreat from the hustle and bustle.</p>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>

            <div className='bio-Data'>

                <div>
                    <h5>1 BHK</h5>
                    <h4>48 Lacs</h4>
                </div>
                <div>
                    <h5>2 BHK</h5>
                    <h4>74 Lacs</h4>
                </div>
                <div>
                    <h5>3 BHK</h5>
                    <h4>95 Lacs</h4>
                </div>
                <div>
                    <h5>4 + S</h5>
                    <h4>1.39 Cr</h4>
                </div>

            </div>

            <div className='arya-amnet'>
                <h1>Amenities</h1>
                <hr></hr>
                <Swiper
                    slidesPerView={slidesPerView}
                    spaceBetween={30}
                    autoplay={{
                        delay: 1000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper">

                    <SwiperSlide className='key_desc'>
                        {/* <img className='key_img' src='https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Dubai_Marina_Skyline.jpg/640px-Dubai_Marina_Skyline.jpg' /> */}
                        <div className='key_features'>
                            <h4>Key Features:</h4>
                            <ul>
                                <li>Gated Community</li>
                                <li>Swimming pool & Toddler pool.</li>
                                <li>Changing Room.</li>
                                <li>Landscape garden cum children play area.</li>
                            </ul>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className='key_desc'>
                        {/* <img className='key_img' src='https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Dubai_Marina_Skyline.jpg/640px-Dubai_Marina_Skyline.jpg' /> */}
                        <div className='key_features'>
                            <h4>Key Features:</h4>
                            <ul>
                                <li>Multi-Gymanasium: Fully equipped Air-Conditioned.</li>
                                <li>100% power back-up for common areas.</li>
                                <li>Regulated power back-up to all units.</li>
                                <li>Society wide communication through EPABX.</li>
                            </ul>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className='key_desc'>
                        {/* <img className='key_img' src='https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Dubai_Marina_Skyline.jpg/640px-Dubai_Marina_Skyline.jpg' /> */}
                        <div className='key_features'>
                            <h4>Key Features:</h4>
                            <ul>
                                <li>CCTV & 24-hour security.</li>
                                <li>High speed lifts & stretcher lift.</li>
                                <li>3-phase power supply.</li>
                                <li>Socity office, security controlled entrance lobby.</li>
                            </ul>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className='key_desc'>
                        {/* <img className='key_img' src='https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Dubai_Marina_Skyline.jpg/640px-Dubai_Marina_Skyline.jpg' /> */}
                        <div className='key_features'>
                            <h4>Key Features:</h4>
                            <ul>
                                <li>STP</li>
                                <li>Portable water supply through Water Treatment Plant</li>
                            </ul>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>

            <div className='arya-location'>
                <h1>Location</h1>
                <hr></hr>
                {/* <div width="100%">
                    <iframe width="100%" height="200" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=2nd%20floor,%20Right%20Wing,%20Commercial%20Complex,%20Acharya%20Vihar,%20Bhubaneswar,%20Odisha%20751013+(Aryans%20Infrastructure%20Private%20Limited)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">measure area map</a></iframe>
                </div> */}
                {/* <div width="100%"><iframe width="100%" height="200" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=177,%20Arakhuda%20Telengapentha,%20Cuttack,%20Odisha%20754001,%20India+(My%20BusinAryavarta-%20Luxury%20Apartmentess%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">area maps</a></iframe></div> */}
                {/* <div width="100%"><iframe width="100%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=177,%20Arakhuda%20Telengapentha,%20Cuttack,%20Odisha%20754001,%20India+(My%20BusinAryavarta-%20Luxury%20Apartmentess%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">area maps</a></iframe></div> */}

                <div width="100%"><iframe width="100%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1869.953100072352!2d85.883969!3d20.386757!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a190dc2fbb953ab%3A0xfcdd0c7b6917d4fe!2sAryavarta-%20Luxury%20Apartment!5e0!3m2!1sen!2sus!4v1689011396253!5m2!1sen!2sus" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>

            </div>

        </div>

    )
}
export default Aryavarta