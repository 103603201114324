import React, { useEffect, useRef } from 'react';
import './Contact.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

import { ToastContainer, toast, useToast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import emailjs from '@emailjs/browser';

const Contact = () => {
  useEffect(() => {
    AOS.init();
  }, [])


  const sendEmail = (e) => {
    e.preventDefault();

    // Three Things Need to added
    const YOUR_SERVICE_ID = "service_spq3tjj"; // Add your EmailJS service ID
    const YOUR_TEMPLATE_ID = "template_rmuaqhw"; // Add your EmailJS template ID
    const YOUR_USER_ID = "KGDT3R9pXjVWs1kOX"; // Add your EmailJS user ID

    emailjs.sendForm(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, e.target, YOUR_USER_ID)
      .then((result) => {
        toast.success('Contact Data Sent Successfully, our team will contact soon.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
        // console.log(result.text);
      }, (error) => {
        console.log(error.text);
        toast.info('There is some error, please try again.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
      });
    e.target.reset();
  };

  return (
    <div className='contact_parent'>

      <div data-aos="fade-left" className='third_contact'>

        <h1>What can we do for you?</h1>
        <p>We are ready to work on a project of any complexity, wheather it's commerical or residential.</p>

        <form onSubmit={sendEmail}>
          <input type="text" name="name" placeholder="Name" required />
          <input type="email" name="email" placeholder="Email" required />
          <input type="text" name="subject" placeholder="Subject" required />
          <textarea name="message" rows="5" placeholder="Message" required></textarea>
          <button type="submit">Submit</button>
        </form>

      </div>

      <ToastContainer />
    </div>
  )
}

export default Contact