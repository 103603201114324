import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import emailjs from 'emailjs-com';

import './Enquiry.css';
import ImageSlider from './ImageSlider/ImageSlider';

const Enquiry = (props) => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');

    const sendEmail = (e) => {
        e.preventDefault();

        const YOUR_SERVICE_ID = "service_spq3tjj"; // Add your EmailJS service ID
        const YOUR_TEMPLATE_ID = "template_s7s03en"; // Add your EmailJS template ID
        const YOUR_USER_ID = "KGDT3R9pXjVWs1kOX"; // Add your EmailJS user ID

        const templateParams = {
            from_name: name,
            phone_number: phone
        };

        emailjs.send(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, templateParams, YOUR_USER_ID)
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        e.target.reset();
        setName('');
        setPhone('');
        props.onHide(); // Close the modal after submission
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className="model_enquiry_header">
                <Modal.Title id="contained-modal-title-vcenter">
                    Our New Project
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="model_enquiry">
                <Form onSubmit={sendEmail}>
                    <div className="img_enquiry">
                        <ImageSlider />
                    </div>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Control
                            type="name"
                            placeholder="Name"
                            value={name}
                            onChange={handleNameChange}
                            autoFocus
                            className="input_enquiry"
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Control
                            type="phone"
                            placeholder="Phone No"
                            value={phone}
                            onChange={handlePhoneChange}
                            autoFocus
                            className="input_enquiry"
                            required
                        />
                    </Form.Group>

                    <div className="enquiry_bottom_button">
                        <Button type="submit" className="close_submit">
                            Submit
                        </Button>
                        <Button className="close_btn" onClick={props.onHide}>
                            Close
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default Enquiry;
