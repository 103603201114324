import React, { useEffect } from 'react';
import './Preloader.css';
import Logo from '../../asset/fav.png';
import logoAgain from '../../asset/Logo/logo_again.png';

import AOS from 'aos';
import 'aos/dist/aos.css';

const Preloader = () => {

    useEffect(() => {
        AOS.init();
    }, [])

    return (
        <div className="preloader">
            <div className='pre_content' data-aos="fade-left">
                <img src={logoAgain} />
            </div>
        </div>
    )
}

export default Preloader