import React, { useEffect, useState } from 'react';
import './Portfolio.css';
import propertyData from '../../static/property.json';
import PropertyDetail from './Property/Property';

import AOS from 'aos';
import 'aos/dist/aos.css';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import { Link, NavLink } from 'react-router-dom';

const Portfolio = () => {
  const [selectedType, setSelectedType] = useState('ALL');
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);

  const filteredData = selectedType === 'ALL'
    ? propertyData.location
    : propertyData.location.filter(property => property.Type === selectedType);

  const handlePropertyClick = (propertyId) => {
    setSelectedPropertyId(propertyId);
  };

  useEffect(() => {
    AOS.init();
  }, [])

  // const navigateToPropertyPage = (propertyId) => {
  //   const url = `/property/${propertyId}`;
  //   window.location.href = url;
  // };

  const navigateToPropertyPage = (propertyId) => {
    setSelectedPropertyId(propertyId);
  };

  const [slidesPerView, setSlidesPerView] = useState(2);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 767) {
        setSlidesPerView(1);
      } else {
        setSlidesPerView(1);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial value

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <div className='property_header'>
        <h2>Experience our Artistry</h2>
        <hr></hr>
      </div>

      <Swiper
        navigation={true}
        slidesPerView={slidesPerView}
        spaceBetween={30}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Navigation]}
        className="mySwiper">

        {/* <div className='property_data'>

          {filteredData.map(property => (
            <SwiperSlide className='slider_div'>
              <div
                className='property_one'
                key={property.id}
                onMouseEnter={() => handlePropertyClick(property.id)}
                onMouseLeave={() => handlePropertyClick(null)}
              >
                <img src={property.imgUrl} alt={property.LocationName} />
                <div className='property_details'>
                  <p>{property.Location}</p>
                  <a href={property.pdf}><h4>{property.Name}</h4></a>
                  <h5>{property.description}</h5>
                  <Link to={`/property/${property.id}`} onClick={() => window.scrollTo(0, 0)}>
                    <button>
                      Know more
                    </button>
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          ))}

        </div> */}

        <div className='property_data'>
          {filteredData.map(property => (
            <SwiperSlide className='slider_div' key={property.id}>
              <div
                className='property_one'
                onMouseEnter={() => handlePropertyClick(property.id)}
                onMouseLeave={() => handlePropertyClick(null)}
              >
                <img src={property.imgUrl} alt={property.LocationName} />
                <div className='property_details'>
                  <p>{property.Location}</p>
                  <a href={property.pdf}><h4>{property.Name}</h4></a>
                  <h5>{property.description}</h5>
                  <Link to={`/property/${property.id}`} onClick={() => window.scrollTo(0, 0)}>
                    <button>
                      Know more
                    </button>
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </div>

      </Swiper>

      {selectedPropertyId && <PropertyDetail propertyId={selectedPropertyId} />}
    </div>
  );
};

export default Portfolio;

