import React, { useEffect } from 'react';
import './Services.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

import ServiceLogo from '../../../../asset/service.png';
import Constrcuction from '../../../../asset/construction.png';
import archiLogo from '../../../../asset/archi.png'

const Services = () => {

    useEffect(() => {
        AOS.init();
    }, [])

    return (
        <div className='services_parent'>
            <h2>Services</h2>
            <hr></hr>
            <div className='services_div'>

                <div data-aos-duration="2000" data-aos="flip-up" className='service_one'>
                    <img src={ServiceLogo} />
                    <h3 className='service_h3'>Preconstruction Planning</h3>
                    <p>Preconstruction Planning is a critical process in construction managment that involves identifying project goals and objectives, analyzing the feasibility of the project, and establishing a plan for its execution</p>

                </div>

                <div data-aos-duration="2000" data-aos="flip-right" className='service_one'>
                    <img src={archiLogo} />
                    <h3 className='service_h3'>Architectural Modelling</h3>
                    <p>Architectural Modeling refers to the process of creating a digital representation or a 3D model of a building or structure using specialized software. Architectural modeling is an essential part of the design and planninig process in architecture, as it allows architects and designers to visualize and communicate their ideas effectively.</p>
                </div>


                <div data-aos-duration="2000" data-aos="flip-left" className='service_one'>
                    <img src={Constrcuction} />
                    <h3 className='service_h3'>Construction Management</h3>
                    <p>Construction management is the process of planning, coordinating, and controlling a construction project from start to finish, it involves mangment the resources, such as labor, materials, and equipment, to ensure that the project is completed on time, within budget, and to the required quality standards.</p>
                </div>

            </div>

        </div>
    )
}

export default Services