import React from 'react';
import './Blog.css';

const Blog = () => {
  return (
    <div>

      <div className='top_blog'>
        <img src='https://papaya-daifuku-31cf0c.netlify.app/img/slid/4.jpg' />
        <div className="centered">
          <h1>Blog</h1>
          <p>Home / <span>Blog</span></p>
        </div>
      </div>


      

    </div>
  )
}

export default Blog