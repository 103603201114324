// import React from 'react'
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Home from '../page/Home/Home';
// import About from '../page/About/About';
// import Contact from '../page/Contact/Contact';
// import Portfolio from '../page/Portfolio/Portfolio';
// import Blog from '../page/Blog/Blog';
// import Header from '../components/Header/Header';
// import Footer from '../components/Footer/Footer';
// import News from '../components/News/News';
// import Enquiry from '../page/enquiry/Enquiry';
// import Property from '../page/Portfolio/Property/Property';
// import Services from '../page/Services/Services';
// import Aryavarta from '../page/Aryavarta/Aryavarta';

// const routes = () => {
//     return (
//         <Router>
//             <News />
//             <Header />
//             <Routes>
//                 <Route path='/' element={<Home />} />
//                 <Route path='/contact' element={<Contact />} />
//                 <Route path='/about' element={<About />} />
//                 <Route path='/blog' element={<Blog />} />
//                 <Route path='/portfolio' element={<Portfolio />} />
//                 <Route path='/enquiry' element={<Enquiry />} />
//                 <Route path='/services' element={<Services />} />
//                 <Route path='/property/:id' element={<Property />} />
//                 <Route path='/aryavarta' element={<Aryavarta />} />
//             </Routes>
//             <Footer />
//         </Router>
//     )
// }

// export default routes;


import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../page/Home/Home';
import About from '../page/About/About';
import Contact from '../page/Contact/Contact';
import Portfolio from '../page/Portfolio/Portfolio';
import Blog from '../page/Blog/Blog';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import News from '../components/News/News';
import Enquiry from '../page/enquiry/Enquiry';
import Property from '../page/Portfolio/Property/Property';
import Services from '../page/Services/Services';
import Aryavarta from '../page/Aryavarta/Aryavarta';
import PageNotFound from '../extra/PageNotFound/PageNotFound';

const routes = () => {
    const hideHeaderFooterNews = window.location.pathname === '/aryavarta';

    return (
        <Router>
            {/* {!hideHeaderFooterNews && <News />} */}
            {!hideHeaderFooterNews && <Header />}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/about" element={<About />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/enquiry" element={<Enquiry />} />
                <Route path="/services" element={<Services />} />
                <Route path="/property/:id" element={<Property />} />
                <Route path="/aryavarta" element={<Aryavarta />} />
                <Route path="/*" element={<PageNotFound />} />
            </Routes>
            {!hideHeaderFooterNews && <Footer />}
        </Router>
    );
};

export default routes;
