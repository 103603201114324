import React, { useEffect, useState } from 'react';
import './Home.css';
import Carousel from '../Carousel/carousel';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Services from './components/Services/Services';

import imageFour from '../../asset/home/Gate view.jpg'

import Enquiry from '../../page/enquiry/Enquiry';
import About from './components/About/About';
import Team from './components/Team/Team';
import Future from './components/Future/Future';
import Portfolio from './components/Portfolio/Portfolio';
import Mission from './components/Mission/Mission';
import Contacts from './components/Contact/Contacts';
import Testimonial from './components/Testimonial/Testimonial';
import { NavLink } from 'react-router-dom';

const Home = () => {
  const [showEnquiryModal, setShowEnquiryModal] = useState(false);

  useEffect(() => {
    // This effect will run only on the initial page load
    setShowEnquiryModal(true);
  }, []);

  const closeEnquiryModal = () => {
    setShowEnquiryModal(false);
  };

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <div className="home_parent">
      {showEnquiryModal && (
        <Enquiry show={true} onHide={closeEnquiryModal} />
      )}

      {/* This is for the Carousel  */}
      <Carousel />

      <section id='about'>
        {/* This is for the About  */}
        <About />
      </section>


      <section id='mission'>
        {/* This is for the Mission  */}
        <Mission />
      </section>


      <section id='team'>
        {/* This is for the Team Members  */}
        {/* <Team /> */}
      </section>

      <section id='services'>
        {/* This is for the Services Section  */}
        <Services />
      </section>

      <section id='portfolio'>
        {/* This is for the Property  */}
        <Portfolio />
      </section>


      {/* This is for the Project View  */}
      <div className='project_pic_view'>
        <h2>OUR ON-GOING PURSUITS</h2>
        <hr className='home_project_hr'></hr>

        <div className='project_div'>
          <div className='project_div_img'>

            <img src={imageFour} />

          </div>
          <div className='project_div_text'>
            <a className='nav-link' href='https://drive.google.com/file/d/1AEmvVHqIHCnxnUVoCOcO4StbziDrQh67/view?usp=drive_link' target='_blank'><h1>ARYAVARTA</h1></a>
            <p>The location are surroundings of a project are important factors to consider when selecting a HOME with you hard earned savings. Aryavarta site is just 250 mtrs
              from the NH 16 having advantage of being easily accessible and well-connected to transportation networks, which will be beneficial for the inhabitants The surrounding greenery and water body of "PURI MAIN CANAL" and the skyline view from the apartments overseeing the river "KATHAJODI" at east and "KUAKHAI" of West and the sunset along the development of Smart city, Bhubaneswar & Millennium City, Cuttack also have significant impact by enhancing the overall aesthetic appeal of the site, creating a breathtaking attractive ambience for the inhabitants.
            </p>
            {/* Here replace the url with your drive url where pdf is uploaded and I have added the targe blank */}
            <button><NavLink to='/aryavarta' onClick={() => window.scrollTo(0, 0)}>Know more about this Project</NavLink></button>
          </div>
        </div>
      </div>

      {/* This is for the Future Project  */}
      {/* <Future /> */}

      <section id='testimonial'>
        <h1>Client Review</h1>
        <hr className='hr_test'></hr>
        <Testimonial />
      </section>

      <section id='contact'>
        {/* This is for the Contact Page  */}
        <Contacts />
      </section>

    </div>
  )
}

export default Home;