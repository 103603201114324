import './App.css';
import Routes from './routes/routes';
import React, { useState, useEffect } from 'react';
import Preloader from './extra/Preloader/Preloader';

function App() {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate data loading or API requests
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <div>
      {isLoading ? <Preloader /> : <Routes />}
    </div>
  );
}

export default App;