import React from 'react'
import './Footer.css';
import logo from '../../asset/fav.png';
import logo2 from '../../asset/Logo/logo_again.png';
import map from '../../asset/QR.png';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <footer>

      <div className='footer_link'>
        <div className='footer_about'>
          <img className='logo' src={logo2} />
          <p>Building your vision, creating reality</p>
          <p>Tel/Fax- 9692140408 / 7406712000</p>
          <p>Email- info@aryaninfrastructure.com</p>
          <p>Powered by Web_Bocket Software Pvt Ltd</p>
        </div>
        <div className='footer_link_div'>
          <div>
            <h3>Useful Links</h3>
            <NavLink className="nav-link" to="/about">About</NavLink>
            {/* <NavLink className="nav-link" to="/contact">Contact</NavLink> */}
            <NavLink className="nav-link" to="/Portfolio">Portfolio</NavLink>
            <NavLink className="nav-link" to="/services">Services</NavLink>
            <a className='nav-link' href='https://drive.google.com/file/d/1bbm4ntCBZdJBtvFSItsrlOTk2vyeH5Vd/view' target='_blank'><h3>Disclamer</h3></a>

          </div>
          <img src={map} />
        </div>

      </div>

      <div width="100%"><iframe width="100%" height="200" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=2nd%20floor,%20Right%20Wing,%20Commercial%20Complex,%20Acharya%20Vihar,%20Bhubaneswar,%20Odisha%20751013+(Aryans%20Infrastructure%20Private%20Limited)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">measure area map</a></iframe></div>
      {/* <div width="100%"><iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1%20Grafton%20dfadfaStreet,%20Dublin,%20Ireland+(1%20Doe%20Something)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">distance maps</a></iframe></div> */}

      <div className='footer_icon'>
        <a href='https://www.facebook.com/profile.php?id=100093381295960'><img width="36" height="36" src="https://img.icons8.com/fluency/48/facebook-new.png" alt="facebook-new" /></a>
        <a href='https://instagram.com/aryans_infrastructure?igshid=NTc4MTIwNjQ2YQ=='><img width="36" height="36" src="https://img.icons8.com/fluency/48/instagram-new.png" alt="instagram-new" /></a>
        <a href='https://api.whatsapp.com/send?phone=7406702000'><img width="36" height="36" src="https://img.icons8.com/color/48/whatsapp--v1.png" alt="whatsapp--v1" /></a>
      </div>

    </footer>
  )
}

export default Footer;