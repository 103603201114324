import React, { useEffect } from 'react';
import './Mission.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Mission = () => {

    useEffect(() => {
        AOS.init();
    }, [])

    return (
        <div className='mission_parent'>
            <div data-aos-duration="2000" data-aos="flip-left" className='vision_div'>
                <h2>VISION</h2>
                <p>To set up high standards through meticulously
                    executed business plan advance technological
                    development strategies to cater homes to all
                    segments of the society and at the same time
                    assure safety of customer’s investment.</p>
            </div>
            <div data-aos-duration="2000" data-aos="flip-right" className='mission_div'>
                <h2>MISSION</h2>
                <ul>
                    <li> To cater to every need of customers and
                        ensure their value for money through
                        efficient and aesthetics design.</li>
                    <li> To ensure a strong risk-adjusted returns
                        and reinforce a sense of security for our
                        stakeholders.</li>
                    <li> Guarantee secure, hassle free investment
                        for all customers while adhering to
                        commitments. Investment for all customers
                        while adhering to commitments. </li>
                </ul>

            </div>
        </div>
    )
}

export default Mission