import React from 'react'
import './ImageSlider.css';

import imgTwo from '../../../asset/home/Aerial view (day).jpg';
import imgThree from '../../../asset/home/Aerial view (night).jpg';
import imgFour from '../../../asset/home/Gate view.jpg';


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

const ImageSlider = () => {
    return (
        <Swiper
            spaceBetween={30}
            autoplay={{
                delay: 3000,
                disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper">

            <SwiperSlide className='carousel_slider_enq'>
                <img src={imgFour} alt='img_four' />
            </SwiperSlide>
            <SwiperSlide className='carousel_slider_enq'>
                <img src={imgTwo} alt='img_two' />
            </SwiperSlide>

            <SwiperSlide className='carousel_slider_enq'>
                <img src={imgThree} alt='img_three'/>
            </SwiperSlide>

        </Swiper>
    )
}

export default ImageSlider