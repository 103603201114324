import React, { useEffect } from 'react';


import AOS from 'aos';
import 'aos/dist/aos.css';

import imgOne from '../../asset/home/Aerial view (day).jpg';
import imgTwo from '../../asset/home/Aerial view (night).jpg';
import imgThree from '../../asset/home/Gate view.jpg';
import imgFour from '../../asset/home/Landscape view.jpg';
import imgFive from '../../asset/home/Balcony view.jpg';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";


import './carousel.css';
import { Button } from '@mui/material';

const Carousel = () => {

    useEffect(() => {
        AOS.init();
    }, [])

    return (

        <Swiper
            spaceBetween={30}
            autoplay={{
                delay: 3000,
                disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper">

            <SwiperSlide className='carousel_slider'>
                <img src={imgThree} />

                <div className="overlay">
                    <h1>One's Humble Abode</h1>
                    {/* <p>Our design is make by best designer</p> */}
                    {/* <button>SEE WORK</button> */}
                </div>

            </SwiperSlide>

            <SwiperSlide className='carousel_slider'>
                <img src={imgFive} />
                <div className="overlay">
                    <h1>Enjoy urban living alongside a scenic canvas</h1>
                    {/* <p>Our design is make by best designer</p> */}
                    {/* <button>SEE WORK</button> */}
                </div>
            </SwiperSlide>

            <SwiperSlide className='carousel_slider'>
                <img src={imgFour} />
                <div className="overlay">
                    <h1>Unlimited moments, Unlimited memories</h1>

                    {/* <button>SEE WORK</button> */}
                </div>
            </SwiperSlide>


            <SwiperSlide className='carousel_slider'>
                <img src={imgTwo} />
                <div className="overlay">
                    <h1>A glittering cityscape</h1>
                    {/* <p>Our design is make by best designer</p> */}
                    {/* <button>SEE WORK</button> */}
                </div>
            </SwiperSlide>

        </Swiper>

    )
}

export default Carousel;