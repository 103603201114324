import React, { useState, useEffect } from 'react';
import './Property.css';
import { useParams } from 'react-router-dom';
import propertyData from '../../../static/property.json';


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

const Property = () => {
    const { id } = useParams();
    const [propertyInfo, setPropertyInfo] = useState(null);

    console.log(id);

    useEffect(() => {
        const property = propertyData.location.find(p => p.id === parseInt(id));
        setPropertyInfo(property);
    }, [id]);

    if (!propertyInfo) {
        return <div></div>;
    }

    return (
        <div className='property_parent'>

            <div className='div_one'>

                <div className='swiper_img'>
                    <Swiper
                        spaceBetween={30}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper">

                        <SwiperSlide className='carousel_slider'>
                            <img src={propertyInfo.imgUrl} alt='property_img'/>
                        </SwiperSlide>

                        <SwiperSlide className='carousel_slider'>
                            <img src={propertyInfo.imgUrl2} alt='property_img'/>
                        </SwiperSlide>

                        <SwiperSlide className='carousel_slider'>
                            <img src={propertyInfo.imgUrl3} alt='property_img' />
                        </SwiperSlide>
                        <SwiperSlide className='carousel_slider'>
                            <img src={propertyInfo.imgUrl4} alt='property_img'/>
                        </SwiperSlide>
                    </Swiper>
                </div>

                {/* <div className='property_info'>
                    <h1>Spatial Parameters</h1>
                    <p><span>Category</span> : {propertyInfo.category}</p>
                    <p><span>Total Unit</span>: {propertyInfo.Total_Unit}</p>
                    <p><span>Project Date :</span>Project Date : {propertyInfo.ProjectDate}</p>
                </div> */}

            </div>

            <div className='div_two'>
                <h2>{propertyInfo.LocationName}</h2>
                {/* <h3>{propertyInfo.LocationTitle}</h3> */}
                <p>{propertyInfo.description}</p>
                {/* <button><a href={propertyInfo.pdf}>Download Brochure</a></button> */}
            </div>

        </div>
    );
};

export default Property;