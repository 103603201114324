import React from 'react';
import { NavLink } from 'react-router-dom';
import './PageNotFound.css';

const PageNotFound = () => {
  return (
    <div className='error_parent'>
      <div className='error_div'>

        <h1>404</h1>
        <h3>PAGE NOT FOUND</h3>
        <p>Sorry, the page you're looking doesn't exist. Please go back to home.</p>

        <div>
          <button><NavLink className='nav-link' to="/">GO HOME</NavLink></button>
        </div>

      </div>
    </div>
  )
}

export default PageNotFound