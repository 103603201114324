import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import "./Header.css";
import logo2 from "../../../src/asset/Logo/logo_black.png";
import Enquiry from "../../page/enquiry/Enquiry";

const Header = () => {
	const location = useLocation();
	const [modalShow, setModalShow] = React.useState(false);
	const isHomePage = location.pathname === "/";

	return (
		<nav className='navbar navbar-expand-lg'>
			<div className='container-fluid'>
				<a className='navbar-brand' href='/'>
					<img
						src={logo2}
						style={{ height: "40px", maxWidth: "135px" }}
						className='d-inline-block align-text-top'
						alt='Logo'
					/>
				</a>
				<button
					className='navbar-toggler'
					type='button'
					data-bs-toggle='collapse'
					data-bs-target='#navbarSupportedContent'
					aria-controls='navbarSupportedContent'
					aria-expanded='false'
					aria-label='Toggle navigation'
				>
					<span className='navbar-toggler-icon'></span>
				</button>
				<div className='collapse navbar-collapse' id='navbarSupportedContent'>
					<ul className='navbar-nav ms-auto mb-2 mb-lg-0'>
						{isHomePage ? (
							<>
								<li className='nav-item'>
									<Link className='nav-link active' aria-current='page' to='/'>
										Home
									</Link>
								</li>
								<li className='nav-item'>
									<a className='nav-link' href='#about'>
										About
									</a>
								</li>
								<li className='nav-item'>
									<a className='nav-link' href='#portfolio'>
										Portfolio
									</a>
								</li>
								<li className='nav-item'>
									<a className='nav-link' href='#services'>
										Services
									</a>
								</li>
								<li className='nav-item'>
									<a className='nav-link' href='#contact'>
										Contact
									</a>
								</li>
							</>
						) : (
							<>
								<li className='nav-item'>
									<NavLink className='nav-link' to='/'>
										Home
									</NavLink>
								</li>
								<li className='nav-item'>
									<NavLink className='nav-link' to='/about'>
										About
									</NavLink>
								</li>
								<li class='nav-item'>
									<NavLink className='nav-link' to='/portfolio'>
										Portfolio
									</NavLink>
								</li>
								<li className='nav-item'>
									<NavLink className='nav-link' to='/services'>
										Services
									</NavLink>
								</li>
								{/* <li className="nav-item">
                  <NavLink className="nav-link" to="/contact">Contact</NavLink>
                </li> */}
							</>
						)}
						<li className='nav-item'>
							<a
								className='nav-link enquiry'
								onClick={() => setModalShow(true)}
							>
								Enquiry
							</a>
						</li>
					</ul>
				</div>
			</div>

			<Enquiry show={modalShow} onHide={() => setModalShow(false)} />
		</nav>
	);
};

export default Header;
